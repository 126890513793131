<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <b-row>
    <b-col md="9">
      <Fieldset id="information" :title="$t('Basic Information')">
        <div
          v-for="(item, index) in config"
          :id="String(index)"
          :key="index"
          class="mb-1"
        >
          <b-form-group
            v-if="isShow.includes(item.key)"
            :label="`Key ${index + 1}`"
            label-cols-md="12"
          >
            <b-form class="repeater-form" @submit.prevent="repeateAgain()">
              <b-row>
                <b-col md="4">
                  <b-form-input
                    v-model="config[index].key"
                    placeholder="key"
                    :readonly="true"
                  />
                </b-col>
                <b-col md="7">
                  <b-form-input
                    v-model="config[index].value"
                    placeholder="value"
                  />
                </b-col>
                <!-- <b-col md="1">
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  class="btn-icon rounded-circle"
                  @click="removeItem(index)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-25" />
                </b-button>
              </b-col> -->
              </b-row>
            </b-form>
          </b-form-group>
        </div>
        <!-- <b-form-group label-cols-md="12" label="">
        <div
          v-if="config[0].value.length < 100"
          class="fade-browse"
          @click="repeateAgain()"
        >
          <feather-icon icon="PlusCircleIcon" class="mr-25" />
          {{ $t('More attribute') }} ({{ config.length }}/100)
        </div>
      </b-form-group> -->
      </Fieldset>
    </b-col>
    <b-col md="3">
      <div class="position-sticky top">
        <Fieldset id="status" :title="$t('Status')">
          <hr>
          <div class="text-right">
            <b-button
              variant="primary"
              type="submit"
              class="ml-2"
              @click.prevent="handlerCreate"
            >
              Submit
            </b-button>
          </div>
        </Fieldset>
        <Fieldset id="avatar" :title="$t('Avatar')">
          <FeatureImage :avatar="logo" @previewFiles="previewLogo" />
        </Fieldset>
        <Fieldset id="favicon" title="Favicon">
          <FeatureImage :avatar="favicon" @previewFiles="previewFavicon" />
        </Fieldset>
      </div>
    </b-col>
  </b-row>
</template>
<script>
/* eslint-disable array-callback-return */
/* eslint-disable operator-linebreak */
import {
  BFormInput,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Request from '@/plugins/service/index'
import general from '@/mixins/index'

const isBase64 = require('is-base64')

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BForm,
  },
  directives: {
    Ripple,
  },
  mixins: [general],
  data() {
    return {
      logo: null,
      favicon: null,
      isShow: ['site_name', 'email', 'phone', 'address', 'google_analytics', 'facebook_chat'],
      config: [
        {
          key: '',
          value: '',
        },
      ],
      key: 'buso_client_page_config',
      isExistSetting: false,
    }
  },
  mounted() {
    this.loadConfig()
  },
  methods: {
    async loadConfig() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/setting/${this.key}?site_id=${
            JSON.parse(localStorage.getItem('siteID')).id
          }`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.isExistSetting = true
            const config = JSON.parse(res.data.data.value)
            this.config = config
            this.logo =
              config.filter(val => val.key === 'logo').length > 0
                ? config.filter(val => val.key === 'logo')[0].value
                : this.avatarDefault()
            this.favicon =
              config.filter(val => val.key === 'favicon').length > 0
                ? config.filter(val => val.key === 'favicon')[0].value
                : this.avatarDefault()
          } else {
            this.isExistSetting = false
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerCreate() {
      try {
        const configNew = this.config.filter(
          val => val.key !== 'logo' && val.key !== 'favicon',
        )
        configNew.push({
          key: 'logo',
          value: await this.handlerUpLoadImg(this.logo, 'logo'),
        })
        configNew.push({
          key: 'favicon',
          value: await this.handlerUpLoadImg(this.favicon, 'favicon'),
        })
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          key: this.key,
          value: JSON.stringify(configNew),
        }
        let res = null
        if (!this.isExistSetting) {
          res = await Request.post(
            this.$http,
            `${process.env.VUE_APP_API_URL}/settings`,
            params,
          )
        } else {
          res = await Request.put(
            this.$http,
            `${process.env.VUE_APP_API_URL}/setting/${this.key}`,
            params,
          )
        }
        if (res.status === 200) {
          if (res.data.status) {
            this.loadConfig()
            this.isExistSetting = true
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'Success',
                text: String('Create success'),
              },
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    repeateAgain() {
      this.config.push({
        key: '',
        value: '',
      })
    },
    removeItem(index) {
      this.config.splice(index, 1)
    },
    previewLogo(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.logo = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
    async handlerUpLoadImg(base64, type) {
      if (isBase64(base64, { allowMime: true })) {
        const params = {
          site_id: JSON.parse(localStorage.getItem('siteID')).id,
          type_table: 's_t',
          type: 'desktop',
          avatar: base64,
          table_field: type,
        }
        const res = await Request.post(
          this.$http,
          `${process.env.VUE_APP_API_IMG_URL}/images_only`,
          params,
        )
        if (res.status === 200) {
          return res.data.data.image
        }
      }
      return base64
    },
    previewFavicon(input) {
      const file = input.target.files[0]
      const reader = new FileReader()
      reader.onload = e => {
        this.favicon = e.target.result
      }
      reader.onerror = error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-gray {
  background: #f6f6f6;
  padding: 12px 24px;
}
table tr td {
  border: 1px solid #ebe9f1;
}
.fade-browse {
  border: 1px dashed var(--primary);
  color: var(--primary);
  border-radius: 4px;
  padding: 8px 12px;
  text-align: center;
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
button svg {
  margin-right: 0 !important;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.fade-browse {
  border: 1px dashed var(--primary);
  position: relative;
  input:focus,
  button:focus {
    outline: unset;
    box-shadow: unset;
    border: unset;
  }
  .btn-add {
    position: absolute;
    z-index: 1;
  }
  .img-box {
    position: relative;
    z-index: 2;
    opacity: 0;
  }
}
</style>
